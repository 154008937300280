<template>
  <div class="hk-content">
    <div class="hk-menu">
      <el-menu :default-active="active" @select="handleSelect">
        <el-menu-item index="0">
          <span slot="title">{{ $t('account.purchaseHistory') }}</span>
        </el-menu-item>
        <!-- <el-menu-item index="1">
          <span slot="title">{{ $t('account.rebateAmountRecord') }}</span>
        </el-menu-item> -->
        <el-menu-item index="2">
          <span slot="title">{{ $t('account.addressManagement') }}</span>
        </el-menu-item>
        <el-menu-item index="3">
          <span slot="title">{{ $t('account.changePassword') }}</span>
        </el-menu-item>
        <el-menu-item index="4">
          <span slot="title">{{ $t('account.informationChecking') }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <hk-account-history-detail v-if="detailData" :data=detailData />
    <component
      v-else
      :is="current"
      @get-detail="handleDetail"
    />
  </div>
</template>

<script>
import HkAccountHistory from './history'
import HkAccountHistoryDetail from './history-detail'
import HkAccountPresent from './present'
import HkAccountAddress from './address'
import HkAccountPassword from './password'
import HkAccountPrefile from './prefile'
export default {
  name: 'HkAccount',
  components: {
    HkAccountHistoryDetail
  },
  data () {
    return {
      active: '0',
      detailData: null,
      pages: [
        HkAccountHistory,
        HkAccountPresent,
        HkAccountAddress,
        HkAccountPassword,
        HkAccountPrefile
      ]
    }
  },
  computed: {
    current () {
      return this.pages[this.active]
    }
  },
  methods: {
    handleSelect (index) {
      this.detailData = null
      this.active = index
    },
    handleDetail (data) {
      this.detailData = data
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/index';
</style>
