var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hk-account"},[_c('div',{staticClass:"hk-password"},[_c('el-form',{attrs:{"label-position":"right","model":_vm.data}},[_c('el-form-item',{attrs:{"prop":"oldPassword","label":_vm.$t('account.oldPassword'),"rules":[
          { required: true, message: _vm.$t('forms.required'), trigger: 'blur'},
          { min: 6, message: _vm.$t('forms.minChar', [6]), trigger: 'blur'}
        ]}},[_c('el-input',{attrs:{"type":"password","placeholder":_vm.$t('account.oldPasswordDesc')},model:{value:(_vm.data.oldPassword),callback:function ($$v) {_vm.$set(_vm.data, "oldPassword", $$v)},expression:"data.oldPassword"}})],1),_c('el-form-item',{attrs:{"prop":"newPassword","label":_vm.$t('account.newPassword'),"rules":[
          { required: true, message: _vm.$t('forms.required'), trigger: 'blur'},
          { min: 6, message: _vm.$t('forms.minChar', [6]), trigger: 'blur'}
        ]}},[_c('el-input',{attrs:{"type":"password","placeholder":_vm.$t('account.newPasswordDesc')},model:{value:(_vm.data.newPassword),callback:function ($$v) {_vm.$set(_vm.data, "newPassword", $$v)},expression:"data.newPassword"}})],1),_c('el-form-item',{attrs:{"prop":"password","label":_vm.$t('account.newPasswordAgain'),"rules":[
          { required: true, message: _vm.$t('forms.required'), trigger: 'blur'},
          { min: 6, message: _vm.$t('forms.minChar', [6]), trigger: 'blur'}
        ]}},[_c('el-input',{attrs:{"type":"password","placeholder":_vm.$t('account.newPasswordAgainDesc')},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}})],1),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.changePassword()}}},[_vm._v(_vm._s(_vm.$t('common.confirm')))])],1)],1),_c('el-dialog',{staticClass:"hk-alert",attrs:{"visible":_vm.showAlertDialog},on:{"update:visible":function($event){_vm.showAlertDialog=$event}}},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.alertMessage))])]),_c('div',{staticClass:"hk-alert__button"},[(!_vm.logoutToggle)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.showAlertDialog = false}}},[_vm._v(_vm._s(_vm.$t('common.confirm')))]):_vm._e(),(_vm.logoutToggle)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.logout}},[_vm._v(_vm._s(_vm.$t('header.logout')))]):_vm._e()],1)]),_c('el-dialog',{staticClass:"hk-alert",attrs:{"visible":_vm.reviewDialogShow},on:{"update:visible":function($event){_vm.reviewDialogShow=$event}}},[_c('p',[_vm._v(_vm._s(_vm.$t('common.underReview')))]),_c('div',{staticClass:"hk-alert__button"},[(!_vm.logoutToggle)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.reviewDialogShow = false}}},[_vm._v(_vm._s(_vm.$t('common.confirm')))]):_vm._e(),(_vm.logoutToggle)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.logout}},[_vm._v(_vm._s(_vm.$t('header.logout')))]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }