<template>
  <div class="hk-account">
    <div class="hk-history">
      <el-table :data="tableData" :empty-text="$t('common.noData')">
        <el-table-column :label="$t('account.invoiceDate')" prop="created_at" min-width="120" />
        <el-table-column
          :label="$t('account.receiptDate')"
          prop="display_date"
          class-name="hk-table_center"
          min-width="125"
        />
        <el-table-column :label="$t('account.invoiceNo')" prop="sn" min-width="115" />
        <el-table-column :label="$t('account.status')" min-width="105">
          <template slot-scope="{ row }">
            <p :class="{'hk-status_red': row.status === 0}">
              {{ formatStatus(row.status) }}
              {{ row.status }}
            </p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('account.amount')" min-width="90">
          <template slot-scope="{ row }">
            <p>{{ parseFloat(row.sum) * 100 | money(0) }}</p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('account.details')" min-width="85">
          <template slot-scope="{ row }">
            <a @click="handleDetail(row)">{{ $t('account.view') }}</a>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="hk-history__button">
      <el-button v-on:click="togglePage('prev')" v-if="currentPage > 1">{{ $t('account.prev') }}</el-button>
      <el-button
        v-on:click="togglePage('next')"
        v-if="totalPage >= currentPage"
        type="primary"
      >{{ $t('account.next') }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HkAccountHistory',
  data() {
    this.getTableData()
    this.getPickupDetail()
    return {
      tableData: [],
      currentPage: 1,
      itemPerPage: 8,
      totalPage: 0,
    }
  },
  methods: {
    handleDetail(data) {
      this.$emit('get-detail', data)
    },
    formatStatus(status) {
      const dict = {
        0: this.$t('account.pending'),
        1: this.$t('account.delivering'),
        2: this.$t('account.pickUp'),
        3: this.$t('account.finished'),
      }
      return dict[status]
    },
    getTableData() {
      let self = this
      if (!self.currentPage) {
        self.currentPage = 1
        self.itemPerPage = 8
      } else {
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/order/collection?lang='
      let formData = new FormData()
      formData = {
        page: self.currentPage, // MUST BE A NUMBER
        pageNum: self.itemPerPage, // MUST BE A NUMBER
        lang: this.$store.state.config.language,
      }
      this.axios
        .post(apiCall, formData, {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        })
        .then(function (response) {
          self.tableData = response.data.data.list
          self.tableData.forEach(function (item) {
            if (item.delivery_type_id == 4) {
              item.display_date = item.receive_time
            } else if (item.date == null) {
              item.display_date = item.receive_time
            }
          })
          self.calculatePage(response.data.data.count)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    togglePage(action) {
      let self = this
      if (action == 'next') {
        self.currentPage++
      } else {
        self.currentPage--
      }
      self.getTableData()
    },
    calculatePage(count) {
      let self = this
      let totalItem = count
      let quotient = totalItem / self.itemPerPage
      let remainder = totalItem % self.itemPerPage
      if (remainder != 0) {
        self.totalPage = Math.floor(quotient)
      } else {
        self.totalPage = Math.floor(quotient) - 1
      }
    },
    async getPickupDetail() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi +
        '/api/pick_up_time/list?lang=' +
        self.$store.state.config.language
      let res = await this.axios
        .get(apiCall)
        .then(function (response) {
          let array1 = response.data.data
          let pickupSlot = []
          array1.forEach(function (item) {
            item.period =
              item.start_hour.toString() +
              ':00:00 -' +
              item.end_hour.toString() +
              ':00:00'
            item.dateUnix = Date.parse(item.date)
            pickupSlot.push(item)
          })
          let pickupDateMin = pickupSlot[0].dateUnix
          let pickupDateMax = pickupSlot[pickupSlot.length - 1].dateUnix
          let arr = [pickupDateMin, pickupDateMax]
          self.$store.commit('savePickupDate', arr)
          self.$store.commit('savePickupSlot', pickupSlot)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/history';
</style>
