<template>
  <div class="hk-account">
    <h4>{{ $t('account.informationChecking') }}</h4>
    <el-form label-position="left" @submit.native.prevent>
      <!-- <el-form-item :label="$t('login.loginId')">
        <span>{{ data.company_name }}</span>
      </el-form-item> -->
      <el-form-item :label="$t('login.companyNameCn')">{{ data.company_name_zh }}</el-form-item>
      <el-form-item :label="$t('login.companyNameEn')">{{ data.company_name_en }}</el-form-item>
      <!-- <el-form-item :label="$t('account.deliveryAddress')">{{ data.delivery_address['details'] }}</el-form-item> -->
      <el-form-item :label="$t('login.mailingAddress')">{{ data.mail_address['details'] }}</el-form-item>
      <el-form-item :label="$t('login.businessRegistrationNo')">{{ data.merchants_number }}</el-form-item>
      <el-form-item
        :label="$t('login.businessType')"
        class="hk-form-item_short"
      >{{ data.company_type }}</el-form-item>
      <el-form-item
        :label="$t('login.companyType')"
        class="hk-form-item_checkbox"
      >{{ data.industry_type }}</el-form-item>
      <el-form-item :label="$t('login.avatar')">
        <el-upload
          :action="uploadUrl"
          :file-list="avatarFile"
          :http-request="uploadAvatar"
          :limit="1"
          :on-success="handleAvatarSuccess"
          :before-upload="onBeforeUpload"
          :show-file-list="false"
          :disabled="data.pass == 'WAIT'"
        >
          <img v-if="data.avatar" :src="data.avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon" />
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('login.nick')">
        <hk-edit v-if="data.pass != 'WAIT'" v-model="data.username" :id="'username'" />
        {{ data.pass == 'WAIT' ? data.username : '' }}
      </el-form-item>
      <el-form-item :label="$t('login.contactPersonCn')">
        <hk-edit v-if="data.pass != 'WAIT'" v-model="data.contacts_zh" :id="'contacts_zh'" />
        {{ data.pass == 'WAIT' ? data.contacts_zh : '' }}
      </el-form-item>
      <el-form-item :label="$t('login.contactPersonEn')">
        <hk-edit v-if="data.pass != 'WAIT'" v-model="data.contacts_en" :id="'contacts_en'" />
        {{ data.pass == 'WAIT' ? data.contacts_en : '' }}
      </el-form-item>
      <el-form-item :label="$t('login.email')" class="hk-form-item_short">
        {{ data.email }}
        <i
          class="iconfont icon-edit"
          v-if="data.pass != 'WAIT'"
          @click="editDialogue = true"
        />
      </el-form-item>
      <el-form-item :label="$t('login.phone')">
        <hk-edit v-if="data.pass != 'WAIT'" v-model="data.mobile" :id="'mobile'" />
        {{ data.pass == 'WAIT' ? data.mobile : '' }}
      </el-form-item>
      <!-- <el-form-item :label="$t('login.receiveContactNameZh')">
        <hk-edit v-if="data.pass != 'WAIT'" v-model="data.recive_contact_name_zh" :id="'recive_contact_name_zh'" />
        {{ data.pass == 'WAIT' ? data.recive_contact_name_zh : '' }}
      </el-form-item>
      <el-form-item :label="$t('login.receiveContactNameEn')">
        <hk-edit v-if="data.pass != 'WAIT'" v-model="data.recive_contact_name_en" :id="'recive_contact_name_en'" />
        {{ data.pass == 'WAIT' ? data.recive_contact_name_en : '' }}
      </el-form-item>
      <el-form-item :label="$t('login.receiveContactMobile')">
        <hk-edit v-if="data.pass != 'WAIT'" v-model="data.recive_contact_mobile" :id="'recive_contact_mobile'" />
        {{ data.pass == 'WAIT' ? data.recive_contact_mobile : '' }}
      </el-form-item> -->
      <ul class="hk-upload">
        <li>
          <label>{{ $t('login.BRCopy') }}</label>
          <div class="hk-upload__image">
            <span class="image-preview">
              <a
                :href="data.copy_of_business_registration"
                v-if="data.copy_of_business_registration"
                target="_blank"
              >{{ $t('account.view') }}</a>
            </span>
            <span class="image-preview">
              <span class="upload-stroke">|</span>
            </span>
            <el-upload
              class="image-preview"
              :action="uploadUrl"
              :file-list="brFile"
              :http-request="uploadBr"
              :limit="1"
              :before-upload="onBeforeUpload"
              :show-file-list="false"
              :disabled="data.pass == 'WAIT'"
              v-if="(data.pass != 'WAIT' && brUploadStatus == false)"
            >{{ $t('login.clickToUpload') }}</el-upload>
          </div>
        </li>
        <li>
          <label>{{ $t('login.CICopy') }}</label>
          <div class="hk-upload__image">
            <span class="image-preview">
              <a
                :href="data.copy_of_company_certificate"
                v-if="data.copy_of_company_certificate"
                target="_blank"
              >{{ $t('account.view') }}</a>
            </span>
            <span class="image-preview">
              <span class="upload-stroke">|</span>
            </span>
            <el-upload
              class="image-preview"
              :action="uploadUrl"
              :file-list="ciFile"
              :http-request="uploadCi"
              :limit="1"
              :before-upload="onBeforeUpload"
              :show-file-list="false"
              :disabled="data.pass == 'WAIT'"
              style="text-decoration: underline"
              v-if="(data.pass != 'WAIT' && ciUploadStatus == false)"
            >{{ $t('login.clickToUpload') }}</el-upload>
          </div>
        </li>
      </ul>
      <hk-preview ref="preview" :data="previewData" />
    </el-form>

    <el-dialog class="edit-email hk-regist-dialog" :visible.sync="editDialogue">
      <el-form :model="edit" ref="edit" class="demo-dynamic">
        <el-form-item
          prop="email"
          :rules="[
          { required: true,  message: $t('forms.required'), trigger: 'blur' },
          { type: 'email',  message: $t('forms.emailTypeError'), trigger: ['blur', 'change'] }
          ]"
        >
          <el-input v-model="edit.email" :placeholder="$t('login.registEmail')" />
        </el-form-item>

        <div class="hk-sms">
          <el-input v-model="edit.sms" :placeholder="$t('login.sms')" />
          <el-button
            v-if="!smsButton"
            type="primary"
            @click="sendSMS(edit.email, 'REGISTER')"
          >{{ $t('login.sendSms') }}</el-button>
          <el-button v-if="smsButton" type="info" disabled>{{ smsTimer }}</el-button>
        </div>
        <el-button
          type="primary"
          :disabled="( disableButton || editCheck )"
          @click="verifyCode"
        >{{ $t('common.confirm') }}</el-button>
      </el-form>
    </el-dialog>

    <el-dialog class="hk-alert" :visible.sync="showAlertDialog">
      <div>
        <p>{{ alertMessage }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button
          v-if="!logoutToggle"
          type="primary"
          @click="showAlertDialog = false"
        >{{ $t('common.confirm') }}</el-button>
        <el-button v-if="logoutToggle" type="primary" @click="logout">{{ $t('header.logout') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HkEdit from '@/components/edit'
import HkPreview from '@/components/preview'
export default {
  name: 'HkAccountPrefile',
  components: {
    HkEdit,
    HkPreview,
  },
  data() {
    this.getUserProfile()
    return {
      data: {},
      avatarUrl: require('images/user.png'),
      previewData: [],
      avatarFile: [],
      brFile: [],
      ciFile: [],
      status: '',
      msg: '',
      showAlertDialog: false,
      alertMessage: '',
      editDialogue: false,
      smsButton: false,
      edit: {
        email: '',
        sms: '',
      },
      logoutToggle: false,
      disableButton: false,
      buttonCounter: 0,
      uploadUrl: this.$store.state.config.apiRoot + '/api/upload/image',
      brUploadStatus: false,
      ciUploadStatus: false,
    }
  },
  mounted: function () {
    this.getUserProfile()
  },
  computed: {
    smsTimer() {
      return this.buttonCounter
    },
    editCheck() {
      let self = this
      let status = false
      if (self.edit.email.length == 0 || self.edit.sms.length == 0) {
        status = true
      }
      return status
    },
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.avatarUrl = URL.createObjectURL(file.raw)
    },
    previewShow(src) {
      const el = this.$refs.preview
      this.previewData = [src]
      this.$nextTick(() => {
        el && el.show(0)
      })
    },
    async getUserProfile() {
      let self = this
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi + '/api/user/info?lang=' + this.$store.state.config.language
      let res = await this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.data = response.data.data
          self.status = response.data.data.pass
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    onBeforeUpload(file) {
      if (
        !(
          file.type == 'image/jpeg' ||
          file.type == 'image/gif' ||
          file.type == 'image/png' ||
          file.type == 'application/pdf'
        )
      ) {
        this.alertMessage = this.$t('login.uploadFormatCheckWPDF')
        this.showAlertDialog = true
        return false
      }
      if (file.size / 1024 / 1024 > 3) {
        this.alertMessage = this.$t('login.uploadSizeCheck')
        this.showAlertDialog = true
        return false
      }
    },
    uploadCi(f) {
      let self = this
      let apiCall = f.action
      let formData = new FormData()
      formData.append('image', f.file)
      let header = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.updateProfile('ci', response.data.data.url)
              self.ciUploadStatus = true
              break
            case 1003:
              self.alertMessage = response.data.msg
              self.showAlertDialog = true
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    uploadBr(f) {
      let self = this
      let apiCall = f.action
      let formData = new FormData()
      formData.append('image', f.file)
      let header = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.updateProfile('br', response.data.data.url)
              self.brUploadStatus = true
              break
            case 1003:
              self.alertMessage = response.data.msg
              self.showAlertDialog = true
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    uploadAvatar(f) {
      let self = this
      let apiCall = f.action
      let formData = new FormData()
      formData.append('image', f.file)
      let header = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          self.updateProfile('avatar', response.data.data.url)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    updateProfile(type, url) {
      let self = this
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/user/update'
      let formData = {}
      if (type == 'avatar') {
        formData = { avatar: url }
      } else if (type == 'br') {
        formData = { copy_of_business_registration: url }
      } else if (type == 'ci') {
        formData = { copy_of_company_certificate: url }
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.getUserProfile()
              self.alertMessage = self.$t('login.successfulEdit')
              self.showAlertDialog = true
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    sendSMS(email, type) {
      let self = this
      let validate = false
      this.$refs['edit'].validate((valid) => {
        if (valid) {
          validate = true
        } else {
          alert(self.$t('forms.missingData'))
          return false
        }
      })
      if (!validate) {
        return false
      }
      self.smsButtonToggler()
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/user/sendCode?'
      this.axios
        .post(apiCall, {
          // lang always = zh
          lang: 'zh',
          email: email,
          type: 'CHANGE_EMAIL',
        })
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.alertMessage = self.$t('login.alertMessage.sendSMS.success')
              self.showAlertDialog = true
              break
            default:
              self.alertMessage = response.data.msg
              self.showAlertDialog = true
              self.smsButton = false
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    smsButtonToggler() {
      let self = this
      self.smsButton = true
      self.buttonCounter = 60
      var counter = setInterval(function () {
        if (self.buttonCounter === 0) {
          clearInterval(counter)
          self.smsButton = false
        } else {
          self.buttonCounter--
        }
      }, 1000)
    },
    verifyCode() {
      // USER REGISTRATION => SEND VERIFICATION CODE 邮箱注册接口
      let self = this
      self.disableButton = true
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/user/update'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let formData = new FormData()
      formData = {
        email: self.edit.email,
        code: self.edit.sms,
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.showAlertDialog = true
              self.logoutToggle = true
              self.alertMessage = self.$t(
                'login.alertMessage.resetEmail.success'
              )
              self.showRegistDialog = false
              self.disableButton = false
              self.smsButton = false
              break
            case 2024:
              self.showAlertDialog = true
              self.alertMessage = self.$t(
                'login.alertMessage.resetEmail.emailExist'
              )
              self.disableButton = false
              self.smsButton = false
              break
            case 2003:
              self.showAlertDialog = true
              self.alertMessage = self.$t(
                'login.alertMessage.resetEmail.expiredCode'
              )
              self.disableButton = false
              self.smsButton = false
              break
            case 2002:
              self.showAlertDialog = true
              self.alertMessage = self.$t('login.alertMessage.resetEmail.fail')
              self.disableButton = false
              self.smsButton = false
              break
            default:
              self.showAlertDialog = true
              self.alertMessage = response.data.msg
              self.disableButton = false
              self.smsButton = false
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    handleEdit() {
      let self = this
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/user/update'
      let formData = {}
      formData[this.id] = self.value
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              alert(self.$t('login.successfulSubmitted'))
              break
            case 2024:
              alert(self.$t('login.alertMessage.getPassword.emailExist'))
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    logout() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/user/logout?lang='
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let formData = new FormData()
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.logoutToggle = false
              self.$store.commit('initUser')
              self.$router.push('login')
              break
            case 401:
              self.$store.commit('initUser')
              self.$router.push('login')
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/prefile';
</style>
<style>
.edit-email .el-dialog {
  width: 8.66667rem;
}
.edit-email .el-dialog .el-dialog__body {
  padding: 0.8rem 0.66667rem;
}
.edit-email .el-dialog .el-form {
  padding: 0px;
}
.edit-email .el-form-item__content {
  font-weight: 400 !important;
}
.edit-email .el-dialog .el-form-item {
  margin-bottom: 0.29333rem !important;
}
.edit-email .hk-sms {
  display: flex;
  margin: auto;
  width: 7.33333rem;
}
.edit-email .hk-sms .el-input {
  width: 3.8rem;
  margin-right: 0.4rem;
}
.edit-email .el-button {
  width: 7.33333rem !important;
  margin-top: 0.26667rem;
  line-height: 0.4rem !important;
}
.edit-email .hk-sms .el-button {
  width: 3.13333rem !important;
  margin-top: 0 !important;
  line-height: 0.4 !important;
}
.edit-email .el-input__inner {
  height: 0.8rem;
  margin-bottom: 0.4rem;
  line-height: 0.8rem;
  font-size: 0.26667rem;
  border: 1px solid #393939;
  border-radius: 0;
}
.hk-alert {
  height: 600px;
}
.hk-alert .hk-alert__button {
  padding-top: 30px;
  padding-bottom: 30px;
}
.hk-alert .el-dialog__body {
  padding: 1rem;
}
.el-button:disabled {
  background-color: gray !important;
}
.hk-upload__image {
  display: inline-flex;
}
.el-upload {
  text-decoration: underline;
}
</style>