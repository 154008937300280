var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hk-account"},[_c('div',{staticClass:"hk-table"},[_c('ul',{staticClass:"hk-table__header"},[_c('li',[_vm._v(_vm._s(_vm.$t('account.addressDesc', [_vm.total])))]),_c('li',[_c('el-button',{on:{"click":_vm.handleAddressAdd}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(" "+_vm._s(_vm.$t('cart.addAddress'))+" ")])],1)]),_c('el-table',{attrs:{"data":_vm.tableData,"empty-text":_vm.$t('common.noData')}},[_c('el-table-column',{attrs:{"label":_vm.$t('account.contact'),"prop":"name","min-width":"120"}}),_c('el-table-column',{attrs:{"class-name":"hk-table_left","label":_vm.$t('account.receiptAddress'),"min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.company))]),_c('p',[_vm._v(_vm._s(row.address))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('account.phone'),"prop":"mobile","class-name":"hk-table_left","min-width":"100"}}),_c('el-table-column',{attrs:{"label":_vm.$t('common.edit'),"class-name":"hk-table_edit","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"iconfont icon-edit",on:{"click":function($event){return _vm.editAddress(row.id)}}}),_vm._v(" | "),_c('i',{staticClass:"el-icon-delete-solid",on:{"click":function($event){return _vm.deleteAddress(row.id)}}})]}}])}),_c('el-table-column',{attrs:{"min-width":"100","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_default == 'Y')?_c('p',[_vm._v(_vm._s(_vm.$t('account.default')))]):_c('a',{on:{"click":function($event){return _vm.setDefault(row.id)}}},[_vm._v(_vm._s(_vm.$t('account.setDefault')))])]}}])})],1)],1),_c('el-dialog',{staticClass:"hk-dialog hk-address-dialog",attrs:{"id":"createAddress","title":_vm.$t('cart.addAddress'),"visible":_vm.showAddressDialog},on:{"update:visible":function($event){_vm.showAddressDialog=$event}}},[_c('el-form',{ref:"addressData",attrs:{"label-position":"right","model":_vm.addressData}},[_c('el-form-item',{attrs:{"prop":"mailAddress","label":_vm.$t('common.area'),"rules":[
			      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ]}},[_c('el-select',{attrs:{"placeholder":_vm.$t('common.areaDesc')},model:{value:(_vm.addressData.mailAddress),callback:function ($$v) {_vm.$set(_vm.addressData, "mailAddress", $$v)},expression:"addressData.mailAddress"}},_vm._l((_vm.mailAddressOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-form-item',{staticClass:"hk-form-item_long",attrs:{"label":_vm.$t('common.deliveryAddress'),"prop":"mailAddressDetail","rules":[
			      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('common.deliveryAddressDesc'),"type":"textarea","rows":3},model:{value:(_vm.addressData.mailAddressDetail),callback:function ($$v) {_vm.$set(_vm.addressData, "mailAddressDetail", $$v)},expression:"addressData.mailAddressDetail"}})],1),_c('el-form-item',{staticClass:"hk-form-item_long",attrs:{"prop":"name","label":_vm.$t('common.contact'),"rules":[
			      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('common.contactDesc')},model:{value:(_vm.addressData.name),callback:function ($$v) {_vm.$set(_vm.addressData, "name", $$v)},expression:"addressData.name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('common.phone'),"prop":"phone","rules":[
			      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('common.fillIn')},model:{value:(_vm.addressData.phone),callback:function ($$v) {_vm.$set(_vm.addressData, "phone", $$v)},expression:"addressData.phone"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('common.email')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('common.fillIn')},model:{value:(_vm.addressData.emial),callback:function ($$v) {_vm.$set(_vm.addressData, "emial", $$v)},expression:"addressData.emial"}})],1),_c('div',{staticClass:"hk-dialog__button"},[_c('el-button',{on:{"click":_vm.handleAddressCancel}},[_vm._v(_vm._s(_vm.$t('common.cancel')))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleAddressSave}},[_vm._v(_vm._s(_vm.$t('cart.saveAddress')))]),_c('div',[(_vm.wrongAddressFormat)?_c('span',[_vm._v(_vm._s(_vm.wrongAddressMsg))]):_vm._e()])],1)],1)],1),_c('el-dialog',{staticClass:"hk-dialog hk-address-dialog",attrs:{"id":"updateAddress","title":_vm.$t('cart.addAddress'),"visible":_vm.showUpdateAddressDialog},on:{"update:visible":function($event){_vm.showUpdateAddressDialog=$event}}},[_c('el-form',{ref:"addressData",attrs:{"label-position":"right","model":_vm.addressData}},[_c('el-form-item',{attrs:{"label":_vm.$t('common.area'),"required":""}},[_c('el-select',{attrs:{"placeholder":_vm.$t('common.areaDesc')},model:{value:(_vm.addressData.mailAddress),callback:function ($$v) {_vm.$set(_vm.addressData, "mailAddress", $$v)},expression:"addressData.mailAddress"}},_vm._l((_vm.mailAddressOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-form-item',{staticClass:"hk-form-item_long",attrs:{"label":_vm.$t('common.deliveryAddress'),"prop":"mailAddressDetail","rules":[
			      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('common.deliveryAddressDesc'),"type":"textarea","rows":3},model:{value:(_vm.addressData.mailAddressDetail),callback:function ($$v) {_vm.$set(_vm.addressData, "mailAddressDetail", $$v)},expression:"addressData.mailAddressDetail"}})],1),_c('el-form-item',{staticClass:"hk-form-item_long",attrs:{"label":_vm.$t('common.contact'),"prop":"name","rules":[
			      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('common.contactDesc')},model:{value:(_vm.addressData.name),callback:function ($$v) {_vm.$set(_vm.addressData, "name", $$v)},expression:"addressData.name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('common.phone'),"prop":"phone","rules":[
			      { required: true, message: _vm.$t('forms.required'), trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":_vm.$t('common.fillIn')},model:{value:(_vm.addressData.phone),callback:function ($$v) {_vm.$set(_vm.addressData, "phone", $$v)},expression:"addressData.phone"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('common.email')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('common.fillIn')},model:{value:(_vm.addressData.emial),callback:function ($$v) {_vm.$set(_vm.addressData, "emial", $$v)},expression:"addressData.emial"}})],1)],1),_c('div',{staticClass:"hk-dialog__button"},[_c('el-button',{on:{"click":_vm.handleAddressCancel}},[_vm._v(_vm._s(_vm.$t('common.cancel')))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleEditSave}},[_vm._v(_vm._s(_vm.$t('cart.saveAddress')))]),_c('div',[(_vm.wrongAddressFormat)?_c('span',[_vm._v(_vm._s(_vm.wrongAddressMsg))]):_vm._e()])],1)],1),_c('el-dialog',{staticClass:"hk-alert",attrs:{"visible":_vm.errorDialogue},on:{"update:visible":function($event){_vm.errorDialogue=$event}}},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.errorMsg))])]),_c('div',{staticClass:"hk-alert__button"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.errorDialogue = false}}},[_vm._v(_vm._s(_vm.$t('common.confirm')))])],1)]),_c('el-dialog',{staticClass:"hk-alert",attrs:{"visible":_vm.reviewDialogShow},on:{"update:visible":function($event){_vm.reviewDialogShow=$event}}},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.alertMessage))])]),_c('div',{staticClass:"hk-alert__button"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.reviewDialogShow = false}}},[_vm._v(_vm._s(_vm.$t('common.confirm')))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }