<template>
  <div class="hk-account">
    <div class="hk-present">
      <el-table :data="tableData" :empty-text="$t('common.noData')">
        <el-table-column
          :label="$t('account.date')"
          prop="created_at"
          min-width="120"
        />
        <el-table-column
          :label="$t('account.invoiceNo')"
          prop="sn"
          min-width="100"
        />
        <el-table-column
          :label="$t('account.rebateEarned')"
          min-width="100"
          prop="get"
        >
        <!---
          <template slot-scope="{ row }">
            <p>{{ row.get | money(0) }}</p>
          </template>
        --->
        </el-table-column>
        <el-table-column
          :label="$t('account.rebateUsed')"
          min-width="100"
          prop="use"
        >
        <!---
          <template slot-scope="{ row }">
            <p>{{ row.use | money(0) }}</p>
          </template>
        --->
        </el-table-column>
      </el-table>
    </div>
    <p>{{ $t('account.balance')}}: <span>{{ balance }}</span></p>
  </div>
</template>

<script>
export default {
  name: 'HkAccountPresent',
  data () {
    this.getTableData();
    return {
      balance: '',
      tableData: [],
    }
  },
  methods: {
    getTableData () {
      let self = this;
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/user/backMoney';
      let formData = new FormData();
      formData = {
        lang: this.$store.state.config.language,
      }
      this.axios.get(apiCall,
        {
          headers: {
            'token': localStorage.getItem('SSID')
          }
        },
        formData
      )
      .then(function (response) {
        self.tableData = response.data.data.list;
        self.balance = response.data.data.balance;
      })
      .catch(function (error) {
        console.log(error);
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import './styles/present';
</style>
