<template>
  <div class="hk-account">
    <div class="hk-password">
      <el-form label-position="right" :model="data">
        <el-form-item 
          prop="oldPassword"
          :label="$t('account.oldPassword')"
          :rules="[
            { required: true, message: $t('forms.required'), trigger: 'blur'},
            { min: 6, message: $t('forms.minChar', [6]), trigger: 'blur'}
          ]"
            >
          <el-input
            v-model="data.oldPassword"
            type="password"
            :placeholder="$t('account.oldPasswordDesc')"
          />
        </el-form-item>
        <el-form-item 
          prop="newPassword"
          :label="$t('account.newPassword')"
          :rules="[
            { required: true, message: $t('forms.required'), trigger: 'blur'},
            { min: 6, message: $t('forms.minChar', [6]), trigger: 'blur'}
          ]"
          >
          <el-input
            v-model="data.newPassword"
            type="password"
            :placeholder="$t('account.newPasswordDesc')"
          />
        </el-form-item>
        <el-form-item 
          prop="password"
          :label="$t('account.newPasswordAgain')"
          :rules="[
            { required: true, message: $t('forms.required'), trigger: 'blur'},
            { min: 6, message: $t('forms.minChar', [6]), trigger: 'blur'}
          ]"
          >
          <el-input
            v-model="data.password"
            type="password"
            :placeholder="$t('account.newPasswordAgainDesc')"
          />
        </el-form-item>
        <el-button v-on:click="changePassword()" type="primary">{{ $t('common.confirm') }}</el-button>
      </el-form>
    </div>

    <el-dialog
      class="hk-alert"
      :visible.sync="showAlertDialog"
    >
      <div><p>{{ alertMessage }}</p></div>
      <div class="hk-alert__button">
        <el-button v-if="!logoutToggle" type="primary" @click="showAlertDialog = false">{{ $t('common.confirm') }}</el-button>
        <el-button v-if="logoutToggle" type="primary" @click="logout">{{ $t('header.logout') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog class="hk-alert" :visible.sync="reviewDialogShow">
      <p>{{ $t('common.underReview') }}</p>
      <div class="hk-alert__button">
        <el-button v-if="!logoutToggle" type="primary" @click="reviewDialogShow = false">{{ $t('common.confirm') }}</el-button>
        <el-button v-if="logoutToggle" type="primary" @click="logout">{{ $t('header.logout') }}</el-button>
      </div>
    </el-dialog>


  </div>
  
</template>

<script>
export default {
  name: 'HkAccountPassword',
  data () {
    return {
      data: {
        oldPassword: '',
        newPassword: '',
        password: '',
      },
      alertMessage: '',
      showAlertDialog: false,
      reviewDialogShow: false,
      logoutToggle: false,
    }
  },
  methods: {
    changePassword () {
      let self = this;
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/user/updatePassword';
      let md5 = require('md5');
      let formData = new FormData();
      formData = {
        old_password: md5(self.data.oldPassword),
        new_password: md5(self.data.newPassword),
        confirm_password: md5(self.data.password)
      }
      // USER REGISTRATION => SEND VERIFICATION CODE 邮箱注册接口
      this.axios.post(
        apiCall,
        formData,
        {
          headers: {
            'token': localStorage.getItem('SSID')
          }
        }
      )
      .then(function (response) {
        let code = response.data.code;
        switch(code) {
          case 0 :
            self.showAlertDialog = true;
            self.logoutToggle = true;
            self.alertMessage = self.$t('forms.passwordChangeSuccess');
            //location.reload();
            break
          case 402 :
            self.reviewDialogShow = true;
            break
          case 2013 :
          case 2014 :
            self.showAlertDialog = true;
            self.alertMessage = self.$t('forms.oldPasswordWrong');
            break
          case 2018 :
            self.showAlertDialog = true;
            self.alertMessage = self.$t('forms.passwordNotMatch');
            break
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    logout () {
      let self = this;
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/user/logout?lang='
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        }
      };
      let formData = new FormData();
      this.axios.post(apiCall, formData, header)
      .then(function(response) {
        let code = response.data.code;
        switch(code) {
          case 0:
            self.logoutToggle = false;
            self.$store.commit('initUser');
            self.$router.push('login');
            break
          case 401:
            self.$store.commit('initUser');
            self.$router.push('login');
            break
        }
      })
      .catch(function(error) {
        console.log(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/password';
</style>
<style>
.hk-alert {
  height: 600px!important;
}
.hk-alert .hk-alert__button {
  padding-top: 30px!important;
  padding-bottom: 30px!important;
}
.hk-alert .el-dialog__body {
  padding: 1rem!important;
}

</style>
