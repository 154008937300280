<template>
  <div class="hk-edit">
    <el-input ref="input" v-if="isEdit" v-model="str" @blur="finishEdit" />
    <div v-else class="hk-edit__inner">
      {{ value }}
      <i class="iconfont icon-edit" @click="handleEdit" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HkEdit',
  model: {
    prop: 'value',
    event: 'update:value',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    let oldValue = this.value
    return {
      str: '',
      isEdit: false,
      alert: false,
      oldValue: oldValue,
    }
  },
  watch: {
    value: {
      handler(val) {
        this.str = val
      },
      immediate: true,
    },
    str(val) {
      this.value !== val && this.$emit('update:value', val)
    },
  },
  methods: {
    handleEdit() {
      this.isEdit = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    finishEdit() {
      let self = this
      self.isEdit = false
      if (self.id == 'email') {
        if (!confirm(self.$t('account.emailConfirm'))) {
          this.value = this.oldValue
          return false
        }
      }
      /*
      if (self.id == 'mobile') {
        let num = parseInt(self.value)
        if ( self.value.length != 8 ) {
          self.value = self.oldValue
          alert(self.$t('forms.eightChar')); 
          return
        }
      }
      */
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/user/update'
      let formData = {}
      formData[this.id] = self.value
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              alert(self.$t('login.successfulSubmitted'))
              break
            case 2024:
              alert(self.$t('login.alertMessage.getPassword.emailExist'))
              break
            case 2029:
              alert(self.$t('forms.mustBeNumber'))
              self.value = self.oldValue
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.hk-edit {
  .hk-edit__inner {
    height: 40px;
    font-size: $titleSize;
    line-height: 40px;
    color: $blockColor;

    span {
      color: $blockColor;
    }

    .iconfont {
      margin-left: 20px;
      font-size: 24px;
      color: $themeColor;
      cursor: pointer;
    }
  }

  .el-input::v-deep {
    width: 300px;
    height: 40px;

    .el-input__inner {
      padding: 0;
      font-size: $titleSize;
      font-family: 'PingFangSC-Regular', 'PingFang SC', 'Microsoft YaHei',
        sans-serif;
      line-height: 40px;
      color: $blockColor;
      background-color: transparent;
      border: none;
    }
  }

  @include mobile {
    .hk-edit__inner {
      font-size: 26px;
      line-height: 42px;

      .iconfont {
        font-size: 26px;
      }
    }

    .el-input::v-deep {
      .el-input__inner {
        font-size: 26px;
      }
    }
  }
}
</style>
