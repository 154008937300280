<template>
  <div class="hk-account">
    <div class="hk-table">
      <ul class="hk-table__header">
        <li>{{ $t('account.addressDesc', [total]) }}</li>
        <li>
          <el-button @click="handleAddressAdd">
            <i class="el-icon-plus" />
            {{ $t('cart.addAddress') }}
          </el-button>
        </li>
      </ul>
      <el-table :data="tableData" :empty-text="$t('common.noData')">
        <el-table-column :label="$t('account.contact')" prop="name" min-width="120" />
        <el-table-column
          class-name="hk-table_left"
          :label="$t('account.receiptAddress')"
          min-width="150"
        >
          <template slot-scope="{ row }">
            <p>{{ row.company }}</p>
            <p>{{ row.address }}</p>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('account.phone')"
          prop="mobile"
          class-name="hk-table_left"
          min-width="100"
        />
        <el-table-column :label="$t('common.edit')" class-name="hk-table_edit" min-width="100">
          <template slot-scope="{ row }">
            <i v-on:click="editAddress(row.id)" class="iconfont icon-edit" /> |
            <i v-on:click="deleteAddress(row.id)" class="el-icon-delete-solid" />
          </template>
        </el-table-column>
        <el-table-column min-width="100" prop="id">
          <template slot-scope="{ row }">
            <p v-if="row.is_default == 'Y'">{{ $t('account.default') }}</p>
            <a v-on:click="setDefault(row.id)" v-else>{{ $t('account.setDefault') }}</a>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- create address dialogue -->
    <el-dialog
      id="createAddress"
      class="hk-dialog hk-address-dialog"
      :title="$t('cart.addAddress')"
      :visible.sync="showAddressDialog"
    >
      <el-form label-position="right" :model="addressData" ref="addressData">
        <el-form-item
          prop="mailAddress"
          :label="$t('common.area')"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-select v-model="addressData.mailAddress" :placeholder="$t('common.areaDesc')">
            <el-option
              v-for="item in mailAddressOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="hk-form-item_long"
          :label="$t('common.deliveryAddress')"
          prop="mailAddressDetail"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input
            v-model="addressData.mailAddressDetail"
            :placeholder="$t('common.deliveryAddressDesc')"
            type="textarea"
            :rows="3"
          />
        </el-form-item>
        <el-form-item
          class="hk-form-item_long"
          prop="name"
          :label="$t('common.contact')"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input v-model="addressData.name" :placeholder="$t('common.contactDesc')" />
        </el-form-item>
        <el-form-item
          :label="$t('common.phone')"
          prop="phone"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input v-model="addressData.phone" :placeholder="$t('common.fillIn')" />
        </el-form-item>
        <el-form-item :label="$t('common.email')">
          <el-input v-model="addressData.emial" :placeholder="$t('common.fillIn')" />
        </el-form-item>
        <div class="hk-dialog__button">
          <el-button @click="handleAddressCancel">{{ $t('common.cancel') }}</el-button>
          <el-button type="primary" @click="handleAddressSave">{{ $t('cart.saveAddress') }}</el-button>
          <div>
            <span v-if="wrongAddressFormat">{{ wrongAddressMsg }}</span>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <!-- updadte address dialogue -->
    <el-dialog
      id="updateAddress"
      class="hk-dialog hk-address-dialog"
      :title="$t('cart.addAddress')"
      :visible.sync="showUpdateAddressDialog"
    >
      <el-form label-position="right" :model="addressData" ref="addressData">
        <el-form-item :label="$t('common.area')" required>
          <el-select v-model="addressData.mailAddress" :placeholder="$t('common.areaDesc')">
            <el-option
              v-for="item in mailAddressOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="hk-form-item_long"
          :label="$t('common.deliveryAddress')"
          prop="mailAddressDetail"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input
            v-model="addressData.mailAddressDetail"
            :placeholder="$t('common.deliveryAddressDesc')"
            type="textarea"
            :rows="3"
          />
        </el-form-item>
        <el-form-item
          class="hk-form-item_long"
          :label="$t('common.contact')"
          prop="name"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input v-model="addressData.name" :placeholder="$t('common.contactDesc')" />
        </el-form-item>
        <el-form-item
          :label="$t('common.phone')"
          prop="phone"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input v-model="addressData.phone" :placeholder="$t('common.fillIn')" />
        </el-form-item>
        <el-form-item :label="$t('common.email')">
          <el-input v-model="addressData.emial" :placeholder="$t('common.fillIn')" />
        </el-form-item>
      </el-form>
      <div class="hk-dialog__button">
        <el-button @click="handleAddressCancel">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="handleEditSave">{{ $t('cart.saveAddress') }}</el-button>
        <div>
          <span v-if="wrongAddressFormat">{{ wrongAddressMsg }}</span>
        </div>
      </div>
    </el-dialog>

    <el-dialog class="hk-alert" :visible.sync="errorDialogue">
      <div>
        <p>{{ errorMsg }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" @click="errorDialogue = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog class="hk-alert" :visible.sync="reviewDialogShow">
      <div>
        <p>{{ alertMessage }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" @click="reviewDialogShow = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'HkAccountAddress',
  data() {
    this.getAreaOptions()
    this.getTableData()
    return {
      tableData: [],
      total: 2,
      showAddressDialog: false,
      showUpdateAddressDialog: false,
      wrongAddressFormat: false,
      reviewDialogShow: false,
      wrongAddressMsg: '',
      addressData: {
        mailAddress: '',
        mailAddressDetail: '',
        name: '',
        phone: '',
        emial: '',
        id: Number,
      },
      mailAddressOptions: '',
      errorDialogue: false,
      errorMsg: '',
      alertMessage: '',
    }
  },
  methods: {
    handleAddressAdd() {
      this.showAddressDialog = true
    },
    handleAddressCancel() {
      this.showAddressDialog = false
      this.showUpdateAddressDialog = false
    },
    handleAddressSave() {
      let self = this
      let validate = false
      this.$refs['addressData'].validate((valid) => {
        if (valid) {
          validate = true
        } else {
          alert(self.$t('forms.missingData'))
          return false
        }
      })
      if (!validate) {
        return false
      }
      var formData = new FormData()
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/address/create?'
      formData = {
        area_id: self.addressData.mailAddress,
        details: self.addressData.mailAddressDetail,
        name: self.addressData.name,
        mobile: self.addressData.phone,
        email: self.addressData.emial,
      }
      this.axios
        .post(apiCall, formData, {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        })
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.errorMsg = self.$t('detail.addSuccess')
              self.errorDialogue = true
              self.showAddressDialog = false
              self.getTableData()
              self.initAddressData()
              break
            case 402:
              self.alertMessage = self.$t('common.underReview')
              self.reviewDialogShow = true
              self.showAddressDialog = false
              break
            case 400:
              // 參數格式錯誤
              self.errorMsg = self.$t('forms.emailOrPhoneTypeError')
              self.errorDialogue = true
              break
            case 404:
              self.wrongAddressFormat = true
              self.wrongAddressMsg = response.data.msg
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getAddress() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi +
        '/api/address/collection?lang=' +
        this.$store.state.config.language
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      this.axios
        .get(apiCall, header)
        .then(function (response) {})
        .catch(function (error) {
          console.log(error)
        })
    },
    getAreaOptions() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi + '/api/address/areas?lang=' + self.$store.state.config.language
      this.axios
        .get(apiCall, {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        })
        .then(function (response) {
          self.mailAddressOptions = response.data.data
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getTableData() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi +
        '/api/address/collection?lang=' +
        self.$store.state.config.language
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.tableData = response.data.data
          self.total = self.tableData.length
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    setDefault(id) {
      let self = this
      let addressId = id
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/address/setDefault?'
      let formData = new FormData()
      formData = {
        lang: this.$store.state.config.language,
        id: addressId,
      }
      this.axios
        .post(apiCall, formData, {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        })
        .then(function (response) {
          self.getTableData()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    deleteAddress(id) {
      let self = this
      let addressId = id
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/address/del?'
      let formData = new FormData()
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      formData = {
        lang: this.$store.state.config.language,
        id: addressId,
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.alertMessage = self.$t('account.deleteSuccess')
              self.reviewDialogShow = true
              break
            case 2026:
              self.alertMessage = self.$t('account.cannotDeleteRegAddress')
              self.reviewDialogShow = true
              break
          }
          self.getTableData()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    editAddress(id) {
      let self = this
      let addressId = id
      self.showUpdateAddressDialog = true
      // DISPLAYING EDITING DATA ON EDIT FORM
      let editingEntry = self.tableData.filter((data) => data.id === addressId)
      self.addressData = {
        mailAddress: editingEntry[0]['area_id'],
        mailAddressDetail: editingEntry[0]['details'],
        name: editingEntry[0]['name'],
        phone: editingEntry[0]['mobile'],
        emial: editingEntry[0]['email'],
        id: addressId,
      }
    },
    handleEditSave() {
      // api call
      let self = this
      let validate = false
      this.$refs['addressData'].validate((valid) => {
        if (valid) {
          validate = true
        } else {
          alert(self.$t('forms.missingData'))
          return false
        }
      })
      if (!validate) {
        return false
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/address/update?'
      let formData = new FormData()
      formData = {
        lang: this.$store.state.config.language,
        id: self.addressData.id,
        area_id: self.addressData.mailAddress,
        details: self.addressData.mailAddressDetail,
        name: self.addressData.name,
        mobile: self.addressData.phone,
        email: self.addressData.emial,
      }
      this.axios
        .post(apiCall, formData, {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        })
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.getTableData()
              self.showUpdateAddressDialog = false
              self.initAddressData()
              break
            case 402:
              self.alertMessage = self.$t('common.underReview')
              self.reviewDialogShow = true
              self.showUpdateAddressDialog = false
              break
            case 400:
              // 參數格式錯誤
              self.errorMsg = self.$t('forms.emailOrPhoneTypeError')
              self.errorDialogue = true
              break
            case 404:
              self.wrongAddressFormat = true
              self.wrongAddressMsg = response.data.msg
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    initAddressData() {
      this.addressData = {
        mailAddress: 1,
        mailAddressDetail: '',
        name: '',
        phone: '',
        emial: '',
        id: Number,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/address';
</style>
<style>
.hk-alert {
  height: 600px;
}
.hk-alert .hk-alert__button {
  padding-top: 30px;
  padding-bottom: 30px;
}
.hk-alert .el-dialog__body {
  padding: 1rem;
}
</style>

