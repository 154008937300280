<template>
  <div class="hk-account">
    <div class="hk-table">
      <ul class="hk-table__header" :class="{'hk-table__header_grow': data.status !== 0}">
        <li>{{ $t('account.invoiceNumber') }}：{{ data.sn }}</li>
        <li>{{ $t('account.invoiceDate') }}：{{ invoiceDetails.created_at.split(' ')[0] }}</li>
        <li>{{ $t('account.receiptDate') }}：{{ invoiceDetails.receive_time.split(' ')[0] }}</li>
        <li v-if="data.status === 0" class="hk-table__status">{{ formatStatus(data.status) }}</li>
        <li v-if="invoiceDetails.invoice">
          {{ $t('account.invoice') }}：
          <a
            :href="invoiceDetails.invoice"
            target="_blank"
            style="text-decoration: underline;color: #fff;"
          >{{ $t('account.clickToView') }}</a>
        </li>
        <li v-if="invoiceDetails.receipt">
          {{ $t('account.receipt') }}：
          <a
            :href="invoiceDetails.receipt"
            target="_blank"
            style="text-decoration: underline;color: #fff;"
          >{{ $t('account.clickToView') }}</a>
        </li>
      </ul>
      <el-table :data="invoiceDetails.products">
        <el-table-column
          min-width="250"
          class-name="hk-table__product"
          :label="$t('cart.productName')"
        >
          <template slot-scope="{ row }">
            <img :src="row.square_img" />
            <div class="hk-table__info">
              <h4>{{ row.category_name }}</h4>
              <h3>{{ row.name }}</h3>
              <p>{{ $t('common.size') }}：{{ row.size }}</p>
              <p>{{ $t('common.packing') }}：{{ row.package }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="100" :label="$t('cart.price')">
          <template slot-scope="{ row }">
            <p
              v-if="row.single_actual_price < row.single_original_price"
              class="hk-table__prime"
            >HK$ {{ row.single_original_price }}</p>
            <p
              v-if="row.single_actual_price >= row.single_original_price"
              class="hk-table__prime"
              style="text-decoration: none !important"
            >HK$ {{ row.single_original_price }}</p>
            <p
              v-if="row.single_actual_price < row.single_original_price"
              class="hk-table__price"
              :class="{'hk-table__price_red': row.single_original_price}"
            >HK$ {{ row.single_actual_price }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="150" :label="$t('cart.quantity')">
          <template slot-scope="{ row }">
            <el-input-number
              v-if="data.status === 0"
              v-model="row.value"
              :min="1"
              controls-position="right"
            />
            <p v-else>{{ row.num }}</p>
          </template>
        </el-table-column>
        <el-table-column class-name="hk-table_left" min-width="150" :label="$t('cart.subtotal')">
          <template slot-scope="{ row }">
            <p class="hk-table__subtotal">HK$ {{ row.single_actual_price * row.num }}</p>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 未付款 -->
    <div v-if="status === 1 && invoiceDetails.pay_type != 'BANK_TRANSFER'" class="hk-payment">
      <p>{{ $t('account.paymentDesc') }}:</p>
      <ul class="hk-payment__type">
        <li>
          <el-radio v-model="type" :label="1" @change="payType(1)">{{ $t('cart.onlinePayment') }}</el-radio>
          <div class="hk-payment__info">
            <img src="~images/stripe.png" />
            <p>{{ $t('cart.onlinePaymentDesc.1') }}</p>
            <form :action="url" method="POST">
              <stripe-checkout
                :stripe-key="newPublicKey"
                :product="myProduct"
                :button="$t('cart.payment')"
              ></stripe-checkout>
            </form>
          </div>
        </li>
        <li>
          <el-radio v-model="type" :label="2"  @change="payType(2)">{{ $t('cart.offlinePayment') }}</el-radio>
          <div class="hk-payment__info">
            <!-- <el-select v-model="offlinePayment" :placeholder="$t('cart.payment')">
              <el-option
                v-for="item in offlineOption"
                :key="item.type"
                :label="item.label"
                :value="item.type"
                :button="$t('cart.payment')"
              />
            </el-select> -->
            <p>{{ offlinePaymentLabel }}</p>
            <h5>{{ $t('cart.methods.1') }}:</h5>
            <table>
              <tbody>
                <tr>
                  <td colspan="2">
                    轉數快 或 銀行入數/轉帳 - 銀行櫃台、自 動櫃員機、網上理財 或 轉數快
                    <br />FPS or Bank in deposit/transfer – bank counter, ATM, e-banking
                  </td>
                </tr>
                <tr>
                  <td>
                    戶口名稱
                    <br />Account Name
                  </td>
                  <td>HONG KONG SECURE TRANSPORTATION SYSTEMS LTD</td>
                </tr>
                <tr>
                  <td>
                    戶口號碼
                    <br />Account Number
                  </td>
                  <td>395-666100-001</td>
                </tr>
                <tr>
                  <td>
                    銀行
                    <br />Bank
                  </td>
                  <td>HANG SENG BANK LIMITED</td>
                </tr>
              </tbody>
            </table>
            <p class="hk-payment__description">
              為了完成付款，請於系統內上傳付款單的副本 (必須清楚並在上面寫上發票編號)。
              <br />
              <el-upload
                :action="uploadUrl"
                multiple
                :file-list="receiptImage"
                :http-request="receiptUpload"
                :show-file-list="true"
                :before-upload="onBeforeUpload"
              >
                <a>上傳銀行單</a>
              </el-upload>
            </p>
            <p class="hk-payment__description">
              In order to complete the payment, please click on the link below to upload a copy of the payment slip (must be clear and write the invoice number on it).
              <el-upload
                :action="uploadUrl"
                multiple
                :file-list="receiptImage"
                :http-request="receiptUpload"
                :show-file-list="true"
                :before-upload="onBeforeUpload"
              >
                <a>Upload payment slip</a>
              </el-upload>
            </p>
            <!-- <h5>{{ $t('cart.methods.2') }}:</h5> -->
            <!-- <table>
              <tbody>
                <tr>
                  <td colspan="2">郵寄支票 Cheque mailing</td>
                </tr>
                <tr>
                  <td>
                    支票抬頭請寫
                    <br />Make cheque payable to
                  </td>
                  <td>HONG KONG SECURE TRANSPORTATION SYSTEMS LIMITED</td>
                </tr>
                <tr>
                  <td>
                    郵寄地址
                    <br />Mailing address
                  </td>
                  <td>
                    香港新界荃灣海盛路3號TML廣場18樓A室 - 財務部
                    <br />Room A, 18/F, TML Plaza, 3 Hoi Shing Road, Tsuen Wan, N.T., Hong Kong - Finance Department
                  </td>
                </tr>
                <tr>
                  <td>
                    備註
                    <br />Remark
                  </td>
                  <td>
                    請於支票背面寫上公司名稱及訂單編號
                    <br />Please write the company name & invoice number at the back of the cheque
                  </td>
                </tr>
              </tbody>
            </table> -->
            <!-- <p
              class="hk-payment__description"
            >為加快確認付款程序，請於郵寄支票當天以電郵 enquiry@hksts.com.hk 通知我們，請在主旨列明「郵寄支票」，並註明訂單編號。在電郵內請加入以下資料：</p>
            <p
              class="hk-payment__description"
            >In order to handle the payment confirmation process effectively, please inform us by email at enquiry@hksts.com.hk with the subject of “Cheque Sending” and the invoice number. Please include the following information in your email:</p>
            <p class="hk-payment__request">客戶編號 Customer Number: {{ userNum }}</p>
            <p class="hk-payment__request">訂單編號 Order number: {{ data.sn }}</p>
            <p class="hk-payment__request">金額（港幣）Amount (HKD): {{ data.sum }}</p>
            <p class="hk-payment__request">支票號碼 Cheque number: XXXXXXXX</p> -->
          </div>
        </li>
      </ul>
      <p class="hk-tips">{{ $t('account.phoneDesc') }}</p>
      <el-button
        type="primary"
        v-if="type==2"
        :disabled="offlinePaymentToggle"
        @click="goOfflinePayment"
      >{{ $t('common.finish') }}</el-button>
    </div>

    <!-- bank transfer - no receipt -->
    <div v-if="status === 1 && invoiceDetails.pay_type == 'BANK_TRANSFER'" class="hk-payment">
      <ul class="hk-payment__type">
        <li>
          <div class="hk-payment__info">
            <table>
              <tbody>
                <tr>
                  <td colspan="2">
                    轉數快 或 銀行入數/轉帳 - 銀行櫃台、自 動櫃員機、網上理財 或 轉數快
                    <br />FPS or Bank in deposit/transfer – bank counter, ATM, e-banking
                  </td>
                </tr>
                <tr>
                  <td>
                    戶口名稱
                    <br />Account Name
                  </td>
                  <td>HONG KONG SECURE TRANSPORTATION SYSTEMS LTD</td>
                </tr>
                <tr>
                  <td>
                    戶口號碼
                    <br />Account Number
                  </td>
                  <td>395-666100-001</td>
                </tr>
                <tr>
                  <td>
                    銀行
                    <br />Bank
                  </td>
                  <td>HANG SENG BANK LIMITED</td>
                </tr>
              </tbody>
            </table>
            <p class="hk-payment__description">
              為了完成付款，請於系統內上傳付款單的副本 (必須清楚並在上面寫上發票編號)。
              <br />
              <el-upload
                :action="uploadUrl"
                multiple
                :file-list="receiptImage"
                :http-request="receiptUpload"
                :show-file-list="true"
                :before-upload="onBeforeUpload"
                :on-remove="remove"
              >
                <a>上傳銀行單</a>
              </el-upload>
            </p>
            <p class="hk-payment__description">
              In order to complete the payment, please click on the link below to upload a copy of the payment slip (must be clear and write the invoice number on it).
              <el-upload
                :action="uploadUrl"
                multiple
                :file-list="receiptImage"
                :http-request="receiptUpload"
                :show-file-list="true"
                :before-upload="onBeforeUpload"
              >
                <a>Upload payment slip</a>
              </el-upload>
            </p>
          </div>
        </li>
      </ul>
      <p class="hk-tips">{{ $t('account.phoneDesc') }}</p>
      <el-button
        type="primary"
        v-if="type==2"
        :disabled="offlinePaymentToggle"
        @click="goOfflinePayment"
      >{{ $t('common.finish') }}</el-button>
    </div>

    <!-- 自取（未選/已選日期） -->
    <div v-else-if="status == 2 || invoiceDetails.delivery_type_id == 4" class="hk-transport">
      <p v-if="data.pickupDate">
        {{ $t('account.pickUpDate') }}：
        <span>{{ data.pickupDate }}</span>
      </p>
      <template>
        <!---
        <div v-if="!invoiceDetails.date">
          <p>
            <i />
            {{ $t('account.selectPickUpDate') }}:
          </p>
          <el-date-picker
            v-model="pickupDate"
            type="date"
            format="yyyy/MM/dd"
            value-format="yyyy-MM-dd"
            :placeholder="$t('cart.selectDate')"
            :picker-options="pickerOptions"
          />
          <el-select v-model="pickupPeriod" class="select" :placeholder="$t('cart.selectPeriod')">
            <el-option
              v-for="item in pickupPeriods"
              :key="item.id"
              :label="item.period"
              :value="item.id"
            />
          </el-select>
          <el-button
            type="primary"
            style="margin-left: 15px; "
            @click="choosePickupTime"
          >{{ $t('common.confirm') }}</el-button>
        </div>
        --->
        <div>
          <p>
            <i />
            {{ $t('account.pickUpDate') }}:
          </p>
          <p>{{ invoiceDetails.pick_up_plan }}</p>
        </div>
      </template>
    </div>

    <!-- 送貨中/已完成 -->
    <div v-else-if="status === 0" class="hk-address">
      <p>{{ $t('account.deliveryAddress') }}：</p>
      <ul class="hk-address__card" v-for="address in invoiceDetails.address">
        <!---
        <dl v-if="data.status == '已付款' | data.status == 'PAID'">
          <dt>{{ $t('account.deliveryDate') }}：</dt><dd>{{ address.delivery_date }}</dd>
        </dl>
        --->
        <dl>
          <dt>{{ $t('common.company') }}：</dt>
          <dd>{{ address.company_name }}</dd>
        </dl>
        <dl>
          <dt>{{ $t('account.deliveryAddress') }}：</dt>
          <dd>{{ address.details }}</dd>
        </dl>
        <dl>
          <dt>{{ $t('common.contact') }}：</dt>
          <dd>{{ address.name }}</dd>
        </dl>
        <dl>
          <dt>{{ $t('common.phone') }}：</dt>
          <dd>{{ address.mobile }}</dd>
        </dl>
      </ul>
      <p v-if="invoiceDetails.date">{{ $t('account.pickUp') }}</p>
    </div>

    <!-- 付款失敗 -->
    <div v-else-if="status === 4" class="hk-payment">
      <p>{{ $t('account.failedPayment') }}</p>
    </div>

    <div v-else-if="status === 3" class="hk-address">
      <p>{{ $t('account.deliveryAddress') }}：</p>
      <ul class="hk-address__card" v-for="address in invoiceDetails.address">
        <!---
        <dl v-if="data.status == '已付款' | data.status == 'PAID'">
          <dt>{{ $t('account.deliveryDate') }}：</dt><dd>{{ address.delivery_date }}</dd>
        </dl>
        --->
        <dl>
          <dt>{{ $t('common.company') }}：</dt>
          <dd>{{ address.company_name }}</dd>
        </dl>
        <dl>
          <dt>{{ $t('account.deliveryAddress') }}：</dt>
          <dd>{{ address.details }}</dd>
        </dl>
        <dl>
          <dt>{{ $t('common.contact') }}：</dt>
          <dd>{{ address.name }}</dd>
        </dl>
        <dl>
          <dt>{{ $t('common.phone') }}：</dt>
          <dd>{{ address.mobile }}</dd>
        </dl>
      </ul>
      <p v-if="invoiceDetails.address.length == 0">{{ $t('account.pickUp') }}</p>
    </div>

    <el-dialog class="hk-alert" :visible.sync="showAlertDialog">
      <div>
        <p>{{ alertMessage }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" @click="showAlertDialog = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      class="hk-success-dialog"
      :visible.sync="showSuccessDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <i class="iconfont icon-success" />
      <div>
        <h4>{{ $t('common.paymentStatus.offlineNoEmail', [data.sn]) }}</h4>
      </div>
      <a @click="goHome">{{ $t('common.back') }}</a>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'HkAccountHistoryDetail',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    this.getInvoiceDetails()
    this.getStripeKey()
    //this.getPickupDetail();
    let userId = localStorage.getItem('HKSTS__userId')
    let userNum = localStorage.getItem('HKSTS__userNum')
    let pickupDateMin = this.$store.state.config.minDate - 100000000
    let pickupDateMax = this.$store.state.config.maxDate
    let pickupSlot = this.$store.state.config.pickupSlot
    if (!pickupSlot) {
      pickupSlot = [
        {
          date: '2000-01-01',
          dateUnix: 1597363200000,
          end_hour: 20,
          id: 0,
          period: '12:00:00 -20:00:00',
          start_hour: 12,
        },
      ]
    }
    let pickDate = []
    pickupSlot.forEach(function (item) {
      let time = new Date(item.date)
      let yyyy = time.getFullYear()
      let mm =
        time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1 // getMonth() is zero-based
      let dd = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
      let timer = ''.concat(yyyy).concat(mm).concat(dd)
      pickDate.push(timer)
    })

    return {
      invoiceDetails: {},
      userId: userId,
      userNum: userNum,
      pickupSlot: pickupSlot,
      type: 1,
      status: 0,
      address: {},
      pickupDate: '',
      pickupPeriod: '',
      offlinePayment: '',
      offlinePaymentLabel: '',
      offlineOption: [
        {
          type: 'bank_transfer',
          label: '銀行入數 Bank in deposit',
        },
        {
          type: 'cheque_mailing',
          label: '郵寄支票 Cheque mailing',
        },
      ],
      slipUrl: '',
      pickerOptions: {
        disabledDate(time) {
          let yyyy = time.getFullYear()
          let mm =
            time.getMonth() < 9
              ? '0' + (time.getMonth() + 1)
              : time.getMonth() + 1 // getMonth() is zero-based
          let dd = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
          let timer = ''.concat(yyyy).concat(mm).concat(dd)
          return !pickDate.includes(timer)
        },
      },
      pickupDateMin: 0,
      pickupDateMax: 0,
      myProduct: {
        name: 'HKSTS',
        description: '',
        amount: '',
        currency: 'hkd',
        invoiceNum: '',
      },
      url: '',
      publishableKey: '',
      newPublicKey:
        'pk_live_51Gx0zdH3hCX8A8cVD2FslyLShhml7PtFTfjqJZ8yOIgLd8JzL2y7RbDb8t6SfRYAk7AMRohPWfYmHBHIyl1OoZe900ODiS8QhX',
      uploadUrl: this.$store.state.config.apiRoot + '/api/upload/image',
      receiptList: [],
      receiptImage: [],
      showAlertDialog: false,
      alertMessage: '',
      showSuccessDialog: false,
      header: {
        productName: '',
        price: '',
        quantity: '',
        total: '',
      },
      offlinePaymentState: false,
    }
  },
  computed: {
    tableData() {
      return []
    },
    pickupPeriods: function () {
      let dateChoice = this.pickupDate
      let timeChoice = this.pickupSlot.filter((item) => item.date == dateChoice)
      return timeChoice
    },
    computePublicKey() {
      return self.publishableKey
    },
    offlinePaymentToggle() {
      return this.offlinePaymentState
    },
  },
  methods: {
    formatStatus(status) {
      const dict = {
        0: this.$t('account.pending'),
        1: this.$t('account.delivering'),
        2: this.$t('account.pickUp'),
        3: this.$t('account.finished'),
        4: this.$t('account.failedPayment'),
      }
      return dict[status]
    },
    onBeforeUpload(file) {
      if (
        !(
          file.type == 'image/jpeg' ||
          file.type == 'image/gif' ||
          file.type == 'image/png' ||
          file.type == 'application/pdf' ||
          file.type == 'application/PDF'
        )
      ) {
        this.alertMessage = this.$t('login.uploadFormatCheck')
        this.showAlertDialog = true
        return false
      }
      if (file.size / 1024 / 1024 > 3) {
        this.alertMessage = this.$t('login.uploadSizeCheck')
        this.showAlertDialog = true
        return false
      }
    },
    goHome() {
      this.$router.replace({
        name: 'home',
      })
    },
    handleFileUpload() {
      let self = this
      let formData = new FormData()
      let file = this.$refs.fileSlip.files[0]
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/upload/image'
      formData.append('image', file)
      this.axios
        .post(apiCall, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(function (response) {
          self.slipUrl = response.data.data.url
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    goOfflinePayment() {
      let self = this
      self.offlinePaymentState = true
      let offlineType = self.offlinePayment
      if (offlineType != 'bank_transfer' && offlineType != 'cheque_mailing') {
        self.alertMessage = self.$t('cart.missingOfflinePayType')
        self.showAlertDialog = true
        self.offlinePaymentState = false
        return
      }
      if (self.receiptList.length == 0 && offlineType == 'bank_transfer') {
        self.alertMessage = self.$t('login.uploadImageMissing')
        self.showAlertDialog = true
        self.offlinePaymentState = false
        return
      }
      let formData = new FormData()
      if (offlineType == 'bank_transfer') {
        formData = {
          type: offlineType,
          order_sn: self.data.sn,
          voucher_image: self.receiptList,
        }
      } else if (offlineType == 'cheque_mailing') {
        formData = {
          type: offlineType,
          order_sn: self.data.sn,
        }
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/pay/offline'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.showSuccessDialog = true
              self.offlinePaymentState = false
              break
            default:
              self.offlinePaymentState = false
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getInvoiceDetails() {
      let self = this
      let invoiceSN = self.data.sn
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi +
        '/api/order/details?lang=' +
        self.$store.state.config.language +
        '&sn=' +
        invoiceSN
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.invoiceDetails = response.data.data
          let backUrl = self.$store.state.config.stripeBackUrl + invoiceSN
          let backUrlEnc = encodeURIComponent(backUrl)
          let rootApi = self.$store.state.config.apiRoot
          self.myProduct.amount = (self.data.sum * 100).toString()
          self.url =
            rootApi +
            '/api/pay/stripe?token=' +
            localStorage.getItem('SSID') +
            '&order_sn=' +
            invoiceSN +
            '&price=' +
            self.myProduct.amount +
            '&back_url=' +
            backUrlEnc
          self.InvoiceStatusHandler(self.invoiceDetails.status)
          if (self.invoiceDetails.pay_type == 'BANK_TRANSFER') {
            self.type = 2
            self.offlinePayment = 'bank_transfer'
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    receiptUpload(f) {
      let self = this
      let apiCall = f.action
      let formData = new FormData()
      formData.append('image', f.file)
      let header = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          if (response.data.code === 0) {
            self.receiptList.push(response.data.data.url)
            self.alertMessage = self.$t('login.uploadSuccess')
            self.showAlertDialog = true
          } else {
            self.receiptList.push(response.data.data.url)
            self.alertMessage = self.$t('login.uploadFail')
            self.showAlertDialog = true
          }
        })
        .catch(function (error) {
          self.$notify.error({
            title: '上傳失敗',
            message: error,
            showClose: true,
          })
        })
    },
    createDisabledDate(arr) {
      let self = this
      let start = new Date(arr[0].date)
      let end = new Date(arr[arr.length - 1].date)
      let newEnd = new Date()
      const arr2 = []
      newEnd.setDate(end.getDate() + 1)
      var i
      for (i = start; i < newEnd; i.setDate(i.getDate() + 1)) {
        var n = i.toISOString().slice(0, 10).toString()
        var test = arr.filter((item) => item.date == n)
        if (test.length == 0) {
          arr2.push(Date.parse(n))
        }
      }
      self.disabledDateList = arr2
    },
    InvoiceStatusHandler(invoiceStatus) {
      //this.getPickupDetail();
      switch (invoiceStatus) {
        case 'COMPLETE':
          this.status = 0
          break
        case 'SENDING':
          this.status = 0
          break
        case 'UNSEND':
          this.status = 0
          break
        case 'SELF':
          this.status = 2
          //this.getPickupDetail();
          break
        case 'PAID':
          this.status = 0
          break
        case 'UNPAY':
          this.status = 1
          break
        case 'FAILED':
          this.status = 4
          break
        case 'UNVERIFY':
          this.status = 3
          break
      }
    },
    getStripeKey() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/pay/config'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.publishableKey = response.data.data.public_key
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    choosePickupTime() {
      let self = this
      if (!self.pickupPeriod) {
        self.alertMessage = self.$t('account.selectPickUpDate')
        self.showAlertDialog = true
        return
      }
      let formData = new FormData()
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/order/updatePickUpTime'
      formData = {
        sn: self.data.sn,
        pick_up_id: self.pickupPeriod,
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.alertMessage = self.$t('login.successfulSubmitted')
              self.showAlertDialog = true
              self.getInvoiceDetails()
              break
            default:
              self.alertMessage = response.data.msg
              self.showAlertDialog = true
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    payType(type) {
      if(type == 2) {
        this.offlinePayment = 'bank_transfer'
        this.offlinePaymentLabel = '選擇轉數快/銀行入數/轉帳 FPS / Bank in deposit/transfer'
      } else {
        this.offlinePaymentLabel = ''
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './styles/history-detail';
</style>
<style>
button.btn.btn-primary.btn-stripe-checkout {
  width: 200px;
  height: 50px;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: 0 0 20px #333333;
  background-color: white;
}
.select {
  width: 300px;
}
.select .el-input__inner {
  width: 100% !important;
}
.hk-alert {
  height: 600px;
}
.hk-alert .hk-alert__button {
  padding-top: 30px;
  padding-bottom: 30px;
}
.hk-alert .el-dialog__body {
  padding: 1rem;
}
.hk-table__header.hk-table__header_grow {
  display: block !important;
}
.hk-table__header.hk-table__header_grow > li {
  line-height: 2 !important;
}
.btn-stripe-checkout {
  background-color: #3f9b3f !important;
  color: white !important;
}
.el-icon-close {
  display: block !important;
}
.el-upload-list__item {
  font-size: 0.3rem !important;
}
</style>